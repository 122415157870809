'use client';

import { useState, useEffect } from 'react';
import { UserButton, useAuth, useUser } from '@clerk/clerk-react'; // Added useUser to access user metadata
import './UserProfile.css';

const MembershipPage = () => {
    const { userId } = useAuth(); // Get the Clerk User ID
    const { user } = useUser(); // Get the Clerk User object
    const [isProcessing, setIsProcessing] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const [subscriptionActive, setSubscriptionActive] = useState(false); // Initialize as false by default

    useEffect(() => {
        if (user) {
            // Fetch the subscriptionActive status from user metadata
            const subscriptionStatus = user?.unsafeMetadata?.subscriptionActive;
            setSubscriptionActive(subscriptionStatus);
        }
    }, [user]);

    useEffect(() => {
        if (responseMessage) {
            const timer = setTimeout(() => {
                setResponseMessage(null);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [responseMessage]);

    const handleCancelMembership = async () => {
        if (!userId) {
            setResponseMessage('User ID not available. Please log in again.');
            return;
        }

        setIsProcessing(true);
        setResponseMessage(null);

        try {
            console.log('Cancel Membership button clicked');
            const response = await fetch('https://subscription-service-dot-barrysnipes.uc.r.appspot.com/cancel-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ clerkUserId: userId }),
            });

            if (response.ok) {
                setResponseMessage('Membership cancelled successfully. No future charges will occur.');
                setSubscriptionActive(false);
            } else {
                const errorData = await response.json();
                console.error('Failed to cancel subscription:', errorData.error);
                setResponseMessage(`Error: ${errorData.error}`);
            }
        } catch (error) {
            console.error('Error while cancelling membership:', error.message);
            setResponseMessage('An unexpected error occurred while cancelling your membership. Please try again and if the issue persists, please contact customer service.');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="membership-page">
            <div className="manage-membership">
                <h2>SportSnipeAI Subscription</h2>
            </div>
            <div className="subscription-details">
                <h2>Subscription Details:</h2>
                <p>Subscription Type: Monthly</p>
                <p>Subscription Status: {subscriptionActive ? 'Active' : 'Inactive'}</p>
            </div>
            <div className="subscription-details">
                <h2>Manage Subscription:</h2>
                <p>You can cancel your subscription to SportSnipeAI at any time. Please note, cancelling your subscription will result in the loss of access to the application at the end of your current subscription cycle. All sales are final and <b>no prorated refunds</b> will be issued.</p>
                <button
                    onClick={handleCancelMembership}
                    className="cancel-membership-button"
                    disabled={!subscriptionActive || isProcessing}
                >
                    {isProcessing ? 'Processing...' : 'Cancel Subscription'}
                </button>
                {responseMessage && <p className="response-message">{responseMessage}</p>}
            </div>
        </div>
    );
};

export default function UserProfile() {
    return (
        <header>
            <UserButton>
                <UserButton.UserProfilePage
                    label="Subscription"
                    url="membership"
                    labelIcon={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
                        </svg>
                    }
                >
                    <MembershipPage />
                </UserButton.UserProfilePage>
            </UserButton>
        </header>
    );
}
