import React, { useEffect, useState, useRef } from 'react';
import { SignInButton, SignUpButton, useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import logo from './SSAILogo3.png';
import SportSnipeAIChat from './images/SportSnipeAIChat.PNG';
import TermsOnlyModal from './TermsOnlyModal';
import SubscriptionModal from './components/SubscriptionModal'; // Import SubscriptionModal
import FAQModal from './components/FAQModal'; // Import FAQModal
import UserProfile from './UserProfile'; // Import UserProfile component

function LandingPage() {
    const { isLoaded, isSignedIn, user } = useUser();
    const { userId } = useAuth();
    const navigate = useNavigate();

    // Reference to the pricing section
    const pricingSectionRef = useRef(null);

    // User Metadata Checks
    const subscriptionActive = user?.unsafeMetadata?.subscriptionActive === true;

    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [isFAQModalOpen, setIsFAQModalOpen] = useState(false); // State to manage FAQ modal visibility
    const [isLoading, setIsLoading] = useState(false);
    const [isMonthlyLoading, setIsMonthlyLoading] = useState(false);
    const [isAnnualLoading, setIsAnnualLoading] = useState(false);

    const openTermsModal = () => setIsTermsModalOpen(true);
    const closeTermsModal = () => setIsTermsModalOpen(false);
    const openFAQModal = () => setIsFAQModalOpen(true);
    const closeFAQModal = () => setIsFAQModalOpen(false);

    useEffect(() => {
        // Check if the URL has a query parameter indicating that the user should stay
        const urlParams = new URLSearchParams(window.location.search);
        const stayOnLandingPage = urlParams.get('stayOnLandingPage') === 'true';

        // Redirect only if the user is signed in, subscribed, and has no intent to stay on the landing page
        if (isLoaded && isSignedIn && subscriptionActive && !stayOnLandingPage) {
            navigate('/'); // Redirect to the main app if no explicit intent to stay on the landing page
        }
    }, [isLoaded, isSignedIn, subscriptionActive, navigate]);

    // Function to handle the scroll to pricing section
    const handleScrollToPricing = () => {
        pricingSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Function to handle the monthly plan subscription
    const handleMonthlySubscription = async () => {
        setIsLoading(true);
        setIsMonthlyLoading(true); // Specific loading state for Monthly button

        try {
            const response = await fetch('https://subscription-service-dot-barrysnipes.uc.r.appspot.com/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ clerkUserId: userId, plan: 'monthly' }),
            });
            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('Error: Checkout session URL not found.');
                setIsLoading(false);
                setIsMonthlyLoading(false);
            }
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
            setIsLoading(false);
            setIsMonthlyLoading(false);
        }
    };

    // Function to handle the annual plan subscription
    const handleAnnualSubscription = async () => {
        setIsLoading(true);
        setIsAnnualLoading(true); // Specific loading state for Annual button

        try {
            const response = await fetch('https://subscription-service-dot-barrysnipes.uc.r.appspot.com/create-checkout-session-annual', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ clerkUserId: userId, plan: 'annual' }),
            });
            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('Error: Checkout session URL not found.');
                setIsLoading(false);
                setIsAnnualLoading(false);
            }
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
            setIsLoading(false);
            setIsAnnualLoading(false);
        }
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    // If user is signed in and wants to stay on the landing page or if they are not signed in
    return (
        <div className="landing-page">
            {/* Top Navigation Bar */}
            <header className="navbar">
                <div className="container">
                    <div className="navbar-left">
                        {/* Make the logo clickable to navigate to the landing page with intent to stay */}
                        <img
                            src={logo}
                            alt="SportSnipeAI"
                            className="logo"
                            onClick={() => navigate('/landing?stayOnLandingPage=true')}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <div className="navbar-right">
                        {/* FAQ Button */}
                        <button
                            className="nav-button"
                            onClick={openFAQModal}
                        >
                            FAQ
                        </button>
                        {/* Pricing Button */}
                        <button className="nav-button" onClick={handleScrollToPricing}>
                            Pricing
                        </button>
                        {isSignedIn ? (
                            <>
                                <UserProfile /> {/* Use the reusable UserProfile component */}
                                {subscriptionActive ? (
                                    <button className="nav-button get-started" onClick={() => navigate('/')}>
                                        Get Picks
                                    </button>
                                ) : (
                                    <button className="nav-button get-started" onClick={() => setShowSubscriptionModal(true)}>
                                        Subscribe
                                    </button>
                                )}
                            </>
                        ) : (
                            <>
                                <SignInButton mode="modal">
                                    <button className="nav-button log-in">Log In</button>
                                </SignInButton>
                                <SignUpButton mode="modal">
                                    <button className="nav-button get-started">Get Started</button>
                                </SignUpButton>
                            </>
                        )}
                    </div>
                </div>
            </header>

            {/* Hero Section */}
            <section className="hero">
                <div className="container">
                    <h1 className="hero-title">Find The Edge With Barry Snipes</h1>
                    <p className="hero-subtitle">Smart Bets, Sharp Angles: Your Personal Sports Handicapper</p>
                </div>
            </section>

            {/* About Section */}
            <section className="barry-about-section">
                <div className="barry-about-content">
                    <div className="barry-about-header">
                        <h2>Bet Smarter, The Barry Way</h2> <br />
                        <p>
                            When it comes to sports betting, winning demands more than just gut feelings or hopeful guesses. It takes insight, discipline, and strategy - that's where Barry Snipes comes in. Barry delivers sharp insights with a focused approach, drawing on years of experience and a method that's grounded in careful analysis and machine learning.
                        </p><br />
                        <p>
                            SportsSnipeAI doesn't promise a win every time - because no one can. But what it does offer is a structured, data-driven approach that gives you a real edge over gut instinct. Barry's dedicated to diving deep into every angle, so you can feel confident you're making informed bets.
                        </p><br />
                        <p>
                            Barry Snipes isn't just about giving you picks - he's here to help you understand why those picks make sense. If you're looking to bet smarter and more consistently, Barry's got you covered. No hype, just a seasoned approach to the game. Welcome to sports handicapping, the Barry way.
                        </p>
                    </div>
                </div>
                <div className="barry-about-image">
                    <img src={SportSnipeAIChat} alt="Barry Info Section" />
                </div>
            </section>

            {/* Pricing Section */}
            <section ref={pricingSectionRef} className="pricing-section">
                <div className="container">
                    <h2>Ready to Get Started?</h2>
                    <p>Find the winning edge with Barry's insights. Choose the plan that works for you:</p>
                    <div className="plan-options">
                        <div className="plan-option">
                            <h3>Monthly*</h3>
                            <p>$10.99</p>
                            <ul>
                                <li>Create your account and sign up for a subscription</li>
                                <li>One month of unlimited access</li>
                            </ul>
                            {!subscriptionActive && (
                                <div className="button-wrapper">
                                    {isSignedIn ? (
                                        <button
                                            className="plan-button"
                                            onClick={handleMonthlySubscription}
                                            disabled={isLoading && isMonthlyLoading}
                                        >
                                            {isMonthlyLoading ? 'Processing...' : 'Choose Plan'}
                                        </button>
                                    ) : (
                                        <SignUpButton mode="modal">
                                            <button className="plan-button">Get Started</button>
                                        </SignUpButton>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="plan-option">
                            <div className="ribbon">1 Month Free</div> {/* Ribbon */}
                            <h3>Annual*</h3>
                            <p>$119.99</p>
                            <ul>
                                <li>Create your account and sign up for a subscription</li>
                                <li>One year of unlimited access</li>
                            </ul>
                            {!subscriptionActive && (
                                <div className="button-wrapper">
                                    {isSignedIn ? (
                                        <button
                                            className="plan-button"
                                            onClick={handleAnnualSubscription}
                                            disabled={isLoading && isAnnualLoading}
                                        >
                                            {isAnnualLoading ? 'Processing...' : 'Choose Plan'}
                                        </button>
                                    ) : (
                                        <SignUpButton mode="modal">
                                            <button className="plan-button">Get Started</button>
                                        </SignUpButton>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="pricing-footer">
                        <p>*Membership renews automatically based on your selected plan (monthly or annually). Cancel anytime.</p>
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="landing-footer">
                <div className="container">
                    <p>&copy; {new Date().getFullYear()} SportSnipeAI LLC. All rights reserved.</p>

                    <p>If you or someone you know has a gambling problem and wants help, call 1-800-GAMBLER</p>
                    <p>
                        By using this service, you agree to our <button className="terms-link" onClick={openTermsModal}>Terms & Conditions</button>.
                    </p>
                </div>
            </footer>

            {/* TermsOnlyModal */}
            {isTermsModalOpen && <TermsOnlyModal onClose={closeTermsModal} />}

            {/* SubscriptionModal */}
            {showSubscriptionModal && (
                <SubscriptionModal
                    user={user}
                    onClose={() => setShowSubscriptionModal(false)}
                />
            )}

            {/* FAQModal */}
            {isFAQModalOpen && <FAQModal onClose={closeFAQModal} />}
        </div>
    );
}

export default LandingPage;
