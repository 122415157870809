// src/components/FAQModal.js

import React, { useEffect } from 'react';
import './FAQModal.css'; // Import styles for the modal

function FAQModal({ onClose }) {
    // Add useEffect to listen for clicks outside of the modal to close it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (event.target.classList.contains('faq-modal-overlay')) {
                onClose();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <div className="faq-modal-overlay">
            <div className="faq-modal">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <h2>Frequently Asked Questions</h2>
                <div className="faq-content">
                    <h3>How does SportSnipeAI help with sports betting?</h3>
                    <p>SportSnipeAI is your go-to-spot for smart sports betting. With a custom-built prediction model that combines advanced analysis, machine learning, and years of experience, we take the guesswork out of betting and help you place informed, consistent bets that give you a real edge.</p>

                    <h3>What sports does SportSnipeAI cover?</h3>
                    <p>SportSnipeAI is all about quality over quantity. Right now, we cover NFL, NBA, and MLB - sports we know inside and out, and where our insights really shine. By focusing on these three major leagues, we're able to provide razor-sharp analysis, in-depth data, and winning strategies that we're truly great at.</p>

                    <h3>What is the pricing for Barry's insights?</h3>
                    <p>Barry offers two subscription options tailored to fit your needs: a monthly plan for flexibility or an annual plan that comes with a special discount. Visit our Pricing section to pick the plan that works best for you and start gaining the advantage today!</p>

                    <h3>Can Barry guarantee that I'll win?</h3>
                    <p>No one can guarantee wins, and Barry won't pretend otherwise. Sports betting is unpredictable - after all, it's the thrill of the game! What Barry can guarantee, though, is that you'll have smarter, data-driven insights to make more educated bets. Barry's job is to tilt the odds in your favor, giving you the best possible chance to succeed over the long term.</p>

                    <h3>How often are predictions updated?</h3>
                    <p>Barry's insights are always up to date! We continuously pull in odds data as lines evolve. Whether it's an injury update or a sudden shift in the odds, Barry's always in the loop, making sure you are too.</p>

                    <h3>How do I cancel my subscription?</h3>
                    <p>We understand that things change, which is why you can easily cancel your subscription anytime. Simply head over to your profile, navigate to the manage account section, and then under Subscription you can click "Cancel Membership" - no hassle, no worries.</p>

                    {/* Add more FAQ items as needed */}
                </div>
            </div>
        </div>
    );
}

export default FAQModal;
