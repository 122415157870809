// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Clerk and Stripe Imports
import { ClerkProvider } from '@clerk/clerk-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter } from 'react-router-dom';

// Retrieve Keys from Environment Variables
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const stripePubKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

// Validate Environment Variables
if (!clerkPubKey) {
    throw new Error('Missing Clerk publishable key');
}

if (!stripePubKey) {
    throw new Error('Missing Stripe publishable key');
}

// Initialize Stripe
const stripePromise = loadStripe(stripePubKey);

// Define Clerk Appearance
const appearance = {
    variables: {
        colorPrimary: '#007bff',
    },
    elements: {
        card: {
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
        },
    },
};

// Create Root Element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ClerkProvider publishableKey={clerkPubKey} appearance={appearance}>
            <BrowserRouter>
                <Elements stripe={stripePromise}>
                    <App />
                </Elements>
            </BrowserRouter>
        </ClerkProvider>
    </React.StrictMode>
);

// Optional: Measure performance in your app
reportWebVitals();
