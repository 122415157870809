import React, { useState, useRef, useEffect } from 'react';
import './TermsModal.css';

function TermsModal({ onAgree }) {
    const [isAgreeEnabled, setIsAgreeEnabled] = useState(false);
    const termsContentRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = termsContentRef.current;
            // Allow a small margin to ensure it triggers at the bottom
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                setIsAgreeEnabled(true);
            }
        };

        const termsContent = termsContentRef.current;
        termsContent.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => termsContent.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="terms-modal-overlay">
            <div className="terms-modal">
                <h2>User Disclaimer and Agreement</h2>
                <div className="terms-content" ref={termsContentRef}>
                    <p><b>IMPORTANT: Please Read Entirely Before Using This Application</b></p>

                    <p>By clicking "I Agree" below, you acknowledge and agree to the following terms:</p><br />

                    <ol>
                        <li>
                            <b>Information Only:</b> This app provides predictions and insights to assist users in analyzing sports betting lines. However, it does not offer any direct betting services or guarantee the accuracy or reliability of predictions.
                        </li><br />
                        <li>
                            <b>No Liability for User Actions:</b> Any decisions you make based on information provided through this app are solely at your discretion and risk. Neither the app nor any person or entity associated with its creation, operation, or ownership will be liable for any losses, damages, or consequences resulting from the use of this information.
                        </li><br />
                        <li>
                            <b>Release of Liability:</b> You hereby release and forever discharge the app's developers, owners, affiliates, employees, and contractors (collectively, "Released Parties") from any and all claims, demands, damages, costs, and liabilities arising from or relating to your use of this app, including but not limited to losses related to betting, gambling, or other financial transactions.
                        </li><br />
                        <li>
                            <b>Third-Party Services Disclaimer:</b> This app integrates with third-party technologies and services. While we strive to ensure uninterrupted service, we do not control or guarantee the availability, reliability, or functionality of these third-party services. No credits, refunds, or compensation will be provided for disruptions arising from third-party service outages.
                        </li><br />
                        <li>
                            <b>Information Disclaimer:</b> This app provides information and content as-is, without assurances on completeness, reliability, or suitability for any specific purpose. We encourage you to consider this information carefully and apply your own judgment.
                        </li><br />
                        <li>
                            <b>Assumption of Risk:</b> By using this app, you acknowledge and accept all risks associated with its use, including the possibility of financial loss.
                        </li><br />
                    </ol>

                    <p>If you do not agree to these terms, do not use this service.</p><br />

                    <p>By clicking "I Agree" below, you confirm that you have read, understood, and accepted the terms and conditions above.</p>
                </div>
                <button onClick={onAgree} disabled={!isAgreeEnabled}>I Agree</button>
            </div>
        </div>
    );
}

export default TermsModal;
